<template>
  <div>
    <div class="holder">
      <SmallNav />
      <div class="contaner">
        <div class="card_holder">
          <div class="card">
            <div class="agreement_text_header">
              <h5>Rent Financining Agreement</h5>
              <div class="blue_line"></div>
            </div>
            <hr class="divider_line" />

            <div class="tenancy_header">
              <h6>
                This deed is binding agreement between Shelta and this
                subscriber <span>{{ user.firstname }} {{ user.lastname }}</span>
              </h6>
            </div>

            <div class="agreement_body">
              <p>SHELTA covenants as follows:</p>
              <ol>
                <li>
                  To provide the Subscriber with the services applied for after
                  the subscriber has met all the requirements.
                </li>
                <li>
                  To protect the Subscribers information and details from any
                  third party except as provided by law.
                </li>
              </ol>
              <p>The Subscriber covenants as follows;</p>
              <ol>
                <li>
                  That all the details and information provided by me is true
                  and correct.
                </li>
                <li>
                  Shelta has the exclusive right to disqualify me if any
                  information I provide is discovered to be false.
                </li>
                <li>
                  That I pledge to repay my balance rent on or before the exact
                  rent due date as stated in my file.
                </li>
                <li>
                  That I shall never hesitate to repay my outstanding for any
                  reason.
                </li>
                <li>
                  I shall be liable for criminal breach of trust if I attempt to
                  delay, deny Shelta my outstanding payments or defraud Shelta .
                </li>
                <li>
                  That shelta reserves the right the institute criminal or civil
                  action against me to recover its funds. If Shelta has to go
                  through this process I shall bear the financial burden of the
                  process.
                </li>
                <li>
                  That Shelta reserves the right to take any legal steps against
                  me to recover its funds.
                </li>
              </ol>

              <!-- <h6>
                  SHELTA covenants as follows:
                  1. To provide the Subscriber with the services applied for after the subscriber has met all the
                  requirements.
                  2. To protect the Subscribers information and details from any third party except as provided by
                  law.
              </h6> -->
            </div>

            <div class="form-check form_check">
              <input
                class="form-check-input "
                type="checkbox"
                value=""
                id="flexCheckChecked"
                v-model="checkagrement"
              />
              <label class="form-check-label" for="flexCheckChecked">
                I have read, understand and Agreed with all the terms and
                condition of this deed and accept that it shall be binding on me
                at all times and I which to proceed.
                <!-- I admit to have read the RENT FINANCING AGREEMENT, and i agree
                to the stated terms and conditions -->
              </label>
              <p class="checkBoxx">{{ checkBox }}</p>
            </div>
            <div class="sign_agg_btn">
              <!-- @click="signAgreement" -->
              <button @click="signAgreementRenfinacing" class="btn btn-block">
                <!-- data-toggle="modal"
                  data-target="#showpayment" -->
                SIGN
                <i
                  class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                  v-if="loaderAgr"
                ></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal to validate Agrement starts -->
      <div
        class="modal fade"
        id="otp_box"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header opt-head">
              <!-- <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5> -->
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p class="modal_veri_text">
                A verification code has been sent to your registered email.
              </p>
              <h6 class="enter_code_text">Kindly enter the code here</h6>
              <!-- <h5 class="otp__text">{{OTP}}</h5> -->
              <div class="otp-holder">
                <input
                  @keyup="keytab($event)"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  v-model="one"
                  type="number"
                  class="form-control"
                  id="exampleInputEmail1"
                  maxlength="1"
                />
                <input
                  @keyup="keytab($event)"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  v-model="two"
                  type="number"
                  class="form-control"
                  id="exampleInputEmail1"
                  maxlength="1"
                />
                <input
                  @keyup="keytab($event)"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  v-model="three"
                  type="number"
                  class="form-control"
                  id="exampleInputEmail1"
                  maxlength="1"
                />
                <input
                  @keyup="keytab($event)"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  v-model="four"
                  type="number"
                  class="form-control"
                  id="exampleInputEmail1"
                  maxlength="1"
                />
                <input
                  @keyup="keytab($event)"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  v-model="five"
                  type="number"
                  class="form-control"
                  id="exampleInputEmail1"
                  maxlength="1"
                />
              </div>

              <div class="otp__btn">
                <!-- <button class="btn" @click="concatOtp()">
                    Sign Document with OTP
                    <i
                      class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                      v-if="loader"
                    ></i>
                  </button> -->
                <button class="btn" @click="concatOtp">
                  Sign Document with OTP
                  <i
                    class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                    v-if="loader"
                  ></i>
                </button>
                <p>
                  Didn’t get the code?
                  <span @click="concatOtp">Resend Code</span>
                </p>
                <!-- <p>Didn’t get the code? <span @click="signAgreement">Resend Code</span></p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal to validate Agrement ends -->

      <!-- Modal to fund wallet begins -->
      <!-- <div
        class="modal fade"
        id="fundme"
        tabindex="-1"
        role="dialog"
        aria-labelledby="fundme"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="close_bank">
              <small data-dismiss="modal" aria-label="Close">x</small>
            </div>
            <div class="modal-body mb">
              <div class="payment_header">
                <p class="payer_name">Great job</p>
                <div class="check_img">
                  <img src="../assets/images/check-good.svg" alt="check" />
                </div>
                <h5 class="document_text">Document signing complete</h5>
                <p class="electronic_text">
                  An electronic copy of the signed agreement will be sent to
                  your registered email
                </p>
                <h6 class="proceed">Proceed to make payment</h6>
              </div>
              <div class="funds_wrap">
                <h5 class="fund_title">Amount Payable</h5>
                <h4 class="fund_amount">
                  &#8358;{{ Number(paymentData.nextpayment).toLocaleString() }}
                </h4>
                <div class="funds_options">
                  <div class="fund_type">
                    <paystack
                      class="paystackBtn"
                      :amount="paymentData.nextpayment * 100"
                      :email="email"
                      :paystackkey="PUBLIC_KEY"
                      :reference="reference"
                      :callback="processPayment"
                      :close="close"
                    >
                      <div class="paystack_div">
                        <img src="../assets/images/block.svg" alt="" />
                        <small>
                          Paystack
                        </small>
                      </div>
                    </paystack>
                  </div>
                  <div class="fund_type">
                    <button
                      class="btn btn-block bank"
                      @click="movetomodalTransfer"
                    >
                      <img
                        src="../assets/images/double-arrow.svg"
                        alt="transfer"
                      />
                      Bank Transfer
                    </button>
                  </div>
                  <div class="fund_type">
                    <button class="btn btn-block wallet_withdraw" disabled>
                      <img
                        src="../assets/images/pay-wallet.svg"
                        alt="transfer"
                        width="20%"
                      />Pay from wallet
                    </button>
                  </div>
                  <div class="fund_type">
                    <button class="btn btn-block flutter" disabled>
                      <img
                        src="../assets/images/flutter.svg"
                        alt="transfer"
                      />Flutterwave
                    </button>
                  </div>
                  <div class="fund_type">
                    <button class="btn btn-block enaira" disabled>
                      <img
                        src="../assets/images/e-naira.svg"
                        alt="transfer"
                      />e-Naira
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- Modal to Fund wallet -->

      <paymentModal
        @paystackReference="dataFromChild($event)"
        @action="dataFromChild($event)"
        :dataForPayment="modalData"
        :responseData="resData"
        v-model="paymentM"
      />
    </div>
  </div>
</template>

<script>
import SmallNav from "./SmallNav";
import { Service } from "../store/service";
const Api = new Service();
import $ from "jquery";
// import paystack from "vue-paystack";
// import { staticdata } from "../data";
import paymentModal from "../components/PaymentModal.vue";
export default {
  name: "RentFinancingAgreement",
  components: {
    SmallNav,
    // paystack,
    paymentModal
    // FooterMain,
  },
  data() {
    return {
      one: "",
      two: "",
      three: "",
      four: "",
      five: "",
      // uploadFile: "",
      // fileselected: false,
      // uploading: false,
      loader: "",
      loader1: false,
      checkagrement: false,
      OTP: "",
      loaderAgr: "",
      token: "",
      concateAggrment: "",
      agrementData: "",
      agrementdata: "",
      checkBox: "",
      userid: "",
      propertyPin: "",
      propPin: "",
      propId: "",
      modeData: "",
      // PUBLIC_KEY: staticdata.paystackkeylive,
      email: "",
      full_name: "",
      reference: "",
      user: "",
      detailsProps: "",
      paymentData: "",
      // ref: "",
      propertyObject: "",
      modalData: {},
      paymentM: "",
      resData: ""
    };
  },
  mounted() {
    this.userid = JSON.parse(localStorage.getItem("shelta-user")).id;
    this.user = JSON.parse(localStorage.getItem("shelta-user"));
    this.email = this.user.email;
    this.full_name = this.user.firstname + this.user.lastname;
    this.modeData = JSON.parse(localStorage.getItem("switchable"));
    this.moreTenantData();
    // this.fetchPropertyAgrement();
    this.detailsProps = Api.getPropertyDetails();
    // console.log("property details", Api.getPropertyDetails());
    if (Object.keys(this.detailsProps).length === 0) {
      this.$router.push({
        path: `/dashboard/rented`
      });
    } else {
      if (
        this.detailsProps.rentFinancingObject.rentfinanceagreement === "YES"
      ) {
        this.$router.push({
          path: `/dashboard/rented`
        });
      }
    }
  },
  methods: {
    trigerRentPayment() {
      // this.resData = "loading";
      // this.propertyObject = propInfo;
      this.fundLoader = true;
      this.modalData.amount = 0;
      this.modalData.payableAmount = Number(this.detailsProps.paynowvalue);
      this.modalData.subtitle = "Payment Amount";
      this.modalData.paymentType = "rentpayment";
      this.modalData.headerCaption = "RENT PAYMENT";
      this.modalData.headerBackground = "#0033ea";
      // this.modalData.paymentURL = "";
      this.$root.$refs.A.openPaymentOptionsModal(this.detailsProps);
    },
    // processPayment2(res) {
    //   // console.log("clikedd", res);
    //   const ref = res.reference;
    //   if (res.status === "success") {
    //     $("#fundme")
    //       .removeClass("fade")
    //       .modal("hide");
    //     Api.postRequest(`paynow`, {
    //       id: this.paymentData.nextpaymentid,
    //       amount: this.paymentData.nextpayment,
    //       referencecode: ref
    //     })
    //       .then(res => {
    //         console.log("Payment response", res);
    //         if (res.data.success === "success") {
    //           this.$store.commit("setApiSuccess", "Payment is successful");
    //           this.$router.push({
    //             path: `/dashboard/rented`
    //           });
    //         }
    //       })
    //       .catch(err => {
    //         console.log("err", err);
    //         // return this.$store.commit("setApiFailed", "Error occured");
    //       });
    //   }
    //   console.log("refrence key", res, ref);
    //   // window.alert("Refrence key:", this.reference);
    // },
    processPayment(res) {
      // console.log("clikedd", res);
      const ref = res.reference;
      if (res.status === "success") {
        $("#fundme")
          .removeClass("fade")
          .modal("hide");
        Api.postRequest(`paynow`, {
          id: this.detailsProps.switchid,
          amount: Number(this.modalData.payableAmount),
          referencecode: ref
        })
          .then(res => {
            // console.log("rented payment response", res);
            if (res.data.success === "success") {
              this.$store.commit("setApiSuccess", "Payment is successful");
              this.$router.push({
                path: `/dashboard/rented`
              });
            }
            if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            console.log("err", err);
            return this.$store.commit("setApiFailed", "Error occured");
          });
      }
      // console.log("refrence key", res, ref);
      // window.alert("Refrence key:", this.reference);
    },
    // Paystack close function or cancel payment
    close: () => {
      console.log("You closed checkout page");
    },
    dataFromChild(data) {
      if (
        data.action === true &&
        this.modalData.paymentType === "rentpayment" &&
        data.type === "payfromsafe"
      ) {
        this.payMyRentWithSafe();
      }
    },
    async payMyRentWithSafe() {
      // alert("pay with safe");
      this.resData = "loading";
      this.loader = true;
      if (
        this.detailsProps.switchid === "" ||
        this.modalData.payableAmount === ""
      ) {
        this.loader = false;
        return this.$store.commit("setApiFailed", "Payment parameters missing");
      } else {
        try {
          // payrentviasafe
          await Api.postRequest(`paynowwithsafe`, {
            id: this.detailsProps.switchid,
            amount: Number(this.modalData.payableAmount)
          })
            .then(res => {
              // console.log("Rent Payment from safe response", res);
              this.loader = false;
              this.resData = "response";
              if (res.data.success) {
                this.$store.commit("setApiSuccess", "Payment is successful");
                this.$router.push({
                  path: `/dashboard/rented`
                });
              }
              this.resData = "";
              if (res.data.error) {
                return this.$store.commit("setApiFailed", res.data.error);
              }
            })
            .catch(err => {
              this.loader = false;
              this.resData = "response";
              console.log("err", err);
              // return this.$store.commit("setApiFailed", "Error occured");
            });
        } finally {
          // console.log("closed")
        }
      }
    },
    moreTenantData: function() {
      Api.getRequest(`fetchmoretenantdatabyid/${this.userid}`)
        .then(res => {
          // console.log("More data", res);
          this.paymentData = res;
          window.localStorage.setItem(
            "currentpropertydetails",
            JSON.stringify(res.data)
          );
          this.paymentData = JSON.parse(
            window.localStorage.getItem("currentpropertydetails")
          );
          // console.log("datttttttttt", this.paymentData);
        })
        .catch(err => {
          // console.log(err);
          // this.loader = false;
          this.uploading = true;
        });
    },
    // movetomodalTransfer() {
    //   $("#fundme")
    //     .removeClass("fade")
    //     .modal("hide");
    //   $("#showpayment")
    //     .addClass("fade")
    //     .modal("show");
    // },
    async signAgreementRenfinacing() {
      this.loaderAgr = true;
      if (this.checkagrement === false) {
        // this.$store.commit("setApiWarning", "Check the box");
        this.checkBox = "Please check the box";
        this.loaderAgr = false;
      } else {
        this.checkBox = "";
        // $("#fundme")
        //   .addClass("fade")
        //   .modal("show");
        try {
          await Api.postRequest(`signrentfinanceaggreement`, {
            propertyid: this.detailsProps.id
          })
            .then(res => {
              console.log("Agreement response", res);
              if (res.data.error) {
                this.$store.commit("setApiFailed", res.data.error);
              } else {
                // console.log("Signed agreement", res);
                this.$store.commit(
                  "setApiSuccess",
                  "Agreement has been signed, make payment"
                );
                this.loaderAgr = false;
                this.trigerRentPayment();
                // $("#fundme")
                //   .addClass("fade")
                //   .modal("show");
                // this.movetomodalTransfer();
                // this.$router.push({path: `/dashboard/switchagreement`});
              }
            })
            .catch(err => {
              this.trigerRentPayment();
              console.log(err);
              this.loaderAgr = false;
            });
        } finally {
          // console.log("Closed connection");
        }
      }
    },
    async signAgreement() {
      this.loader1 = true;
      console.log("Property pin", this.propPin);
      this.concateAggrment = this.agrementData.concat(this.agreementBody);
      // console.log("Agrement tm", this.checkagrement);
      if (this.checkagrement === false || this.checkagrement === "") {
        // this.$store.commit("setApiWarning", "Check the box");
        this.checkBox = "Please check the box";
        this.loader1 = false;
      } else {
        this.checkBox = "";
        try {
          await Api.postRequest(`signaggreement`, {
            userid: this.userid,
            propertyid: this.propId,
            agreement: this.concateAggrment
            // tenantmodeofpaymentid: this.modeData.
          })
            .then(res => {
              if (res.data.error) {
                this.$store.commit("setApiFailed", res.data.error);
              } else {
                // console.log("Signed agreement", res);
                // this.OTP = res.data.message.otp;
                // console.log("OTP", this.OTP);
                this.$store.commit("setApiSuccess", "OTP has been sent to you");
                this.loader1 = false;
                // this.$router.push({path: `/dashboard/switchagreement`});

                $("#otp_box")
                  .addClass("fade")
                  .modal("show");

                // $("#otp_box").modal("show");
              }
            })
            .catch(err => {
              console.log(err);
              // this.loader = false;
              this.loader1 = true;
            });
        } finally {
          // console.log("Closed connection");
        }
      }
    },
    fetchPropertyAgrement: async function() {
      // console.log('Pinnnnn', this.searchPin);
      // this.$router.push({ path: `${this.searchPin}` });
      this.propPin = this.$route.params.id;
      // const id = this.searchPin;
      console.log("Pin", this.propPin);
      try {
        await Api.getRequest(
          `getagreementbyuseridandpropertypin/${this.userid}/${this.propPin}`
        )
          .then(res => {
            if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            } else {
              // console.log("Property Agrement", res);
              this.agrementdata = res.data;
              this.agrementData = res.data.data;
              this.agreementBody = res.data.body;
              this.propId = res.data.propertyid;
            }
          })
          .catch(err => {
            console.log(err);
            // this.loader = false;
            // this.uploading = true;
          });
      } finally {
        // console.log("Closed connection");
      }

      // console.log('Property ID', id);
    },
    keytab(event) {
      const nextInput = event.srcElement.nextElementSibling; // get the sibling element

      const target = event.target || event.srcElement;
      // const id = target.id;
      // console.log(nextInput); // prints undefined
      if (nextInput === null) {
        // check the maxLength from here
        return;
      } else {
        nextInput.focus();
      } // focus if not null
    },
    concatOtp() {
      const theOtp = this.one.concat(
        this.two,
        this.three,
        this.four,
        this.five
      );
      this.token = theOtp;
      // console.log('one OTP', theOtp);
      this.sendOTP();
    },
    async sendOTP() {
      // this.token = theOtp;
      this.loader = true;
      // console.log('OTP', this.token);
      try {
        await Api.postRequest(`validateagreementtoken`, {
          userid: this.userid,
          token: this.token
        })
          .then(res => {
            if (res.data.error) {
              this.loader = false;
              this.$store.commit("setApiFailed", res.data.error);
            } else {
              $("#otp_box")
                .removeClass("fade")
                .modal("hide");
              $("#fundme")
                .addClass("fade")
                .modal("show");
              // console.log(res);
              this.loader = false;
              this.$store.commit("setApiSuccess", "Agreement signed");
            }
          })
          .catch(err => {
            console.log(err);
            this.loader = false;
          });
      } finally {
        // console.log("Closed connection");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$tatiary: #a2b0e3;
$yellow: #ffb100;
$black: #000000;
$black2: #222222;

@font-face {
  font-family: "Gotham-bold";
  src: local("Gotham-bold"),
    url("../assets/fonts/shelta_fonts/Gotham\ Bold.otf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("../assets/fonts/shelta_fonts/Gotham\ Book.otf") format("truetype");
}
@font-face {
  font-family: "Gotham-medium";
  src: local("Gotham-medium"),
    url("../assets/fonts/shelta_fonts/Gotham\ Medium.otf") format("truetype");
}

.holder {
  height: 100%;
}

.card_holder {
  max-width: 743px;
  width: 100%;
  margin: auto;
  padding-top: 49px;
}

.backt0dashboard {
  margin-right: 10px;
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}
.close_small {
  border-left: 1px solid black;
  padding-left: 10px;
  color: red;
  cursor: pointer;
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
}
.close_small:hover {
  color: rgb(144, 3, 3);
}

.agreement_text_header {
  position: relative;
  h5 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    display: flex;
    padding: 28px 30px 0;
  }
  .blue_line {
    position: absolute;
    background: $primary;
    width: 177px;
    height: 5px;
    bottom: 0%;
    margin-left: 30px;
  }
}

.sign_agg_btn {
  padding: 19px 61px 42px;
  button {
    background: $primary;
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    align-items: center;
    text-align: center;
    outline: 0;
    box-shadow: none;
  }
}

.tenancy_header {
  h6 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 26px;
    text-align: left;
    color: $black;
    margin: 30px;
    margin-bottom: 0;
    span {
      color: $primary;
    }
  }
}

.divider_line {
  margin-top: 0;
}

.agreement_body {
  padding: 0 15px 20px;
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    color: $black;
    padding: 20px 15px 10px;
    text-align: justify;
  }
  ol {
    padding-left: 70px;
    padding-right: 30px;
    text-align: justify;
    margin-bottom: 0;
    // padding-bottom: 20px;
    li {
      font-family: Gotham;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 25px;
      color: $black;
      padding-bottom: 20px;
      text-align: justify;
    }
  }
  // h6 {
  //   font-family: Gotham;
  //   font-style: normal;
  //   font-weight: 400;
  //   font-size: 13px;
  //   line-height: 25px;
  //   color: $black;
  //   padding: 37px 61px;
  //   text-align: justify;
  // }
}

.form_check {
  margin: 19px 20px 19px 61px;
  text-align: start;
  label {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    // line-height: 37px;
    color: rgba(0, 0, 0, 0.8);
  }
}
// form-check-input
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #6c6c6c;
}
.checkBoxx {
  color: #dc3545;
  font-family: Gotham;
  text-align: left;
  padding-top: 0.5rem;
  // margin-bottom: 0;
}

// OTP Agreement begins

.opt-head {
  border-bottom: none;
}

.modal-body {
  .modal_veri_text {
    font-family: Gotham;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 23px;
    align-items: center;
    text-align: center;
    margin-bottom: 0;
    color: $black;
  }
  .enter_code_text {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 23px;
    align-items: center;
    text-align: center;
    color: $black;
    padding-bottom: 2rem;
  }
}

.otp-holder {
  display: flex;
  justify-content: space-evenly;
  input {
    width: 45px;
    border: none;
    border-bottom: 2px solid #000000;
    border-radius: 0;
    box-shadow: none;
  }
}

.otp__text {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  color: #28a745;
  font-weight: bold;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.otp__btn {
  padding: 3rem 10px;
  button {
    background: $primary;
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    align-items: center;
    text-align: center;
    max-width: 377px;
    width: 100%;
    max-height: 45px;
    height: auto;
    box-shadow: none;
    margin-bottom: 2rem;
  }
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    align-items: center;
    text-align: center;
    color: $black;
    span {
      font-weight: bold;
      cursor: pointer;
    }
  }
}

/* OTP Agrement ends */

// Payment option and fund me modal starts
// .close_bank {
//   display: flex;
//   justify-content: right;
//   padding: 10px 15px;
//   align-items: baseline;
//   // background: #efefef;
//   h6 {
//     font-family: Gotham;
//     font-style: normal;
//     font-weight: 500;
//     font-size: 20px;
//     line-height: 30px;
//     color: $black;
//   }
//   small {
//     font-family: Gotham;
//     padding-left: 1px;
//     text-align: center;
//     background: #efefef;
//     // background: #c4c4c4;
//     color: $black;
//     border-radius: 50px;
//     width: 20px;
//     height: 20px;
//     cursor: pointer;
//   }
// }

// .check_img {
//   img {
//     width: 69.85px;
//   }
// }
// .payer_name {
//   font-family: Gotham;
//   font-style: normal;
//   font-weight: 400;
//   font-size: 16px;
//   line-height: 23px;
//   color: rgba(0, 0, 0, 0.8);
//   padding-left: 1rem;
//   padding-right: 1rem;
// }
// .document_text {
//   font-family: Gotham;
//   font-style: normal;
//   font-weight: 700;
//   font-size: 22px;
//   line-height: 23px;
//   color: $primary;
//   margin-top: 16px;
//   color: rgba(0, 0, 0, 0.8);
//   padding-left: 1rem;
//   padding-right: 1rem;
// }

// .proceed {
//   font-family: Gotham;
//   font-style: normal;
//   font-weight: 700;
//   font-size: 14px;
//   line-height: 23px;
//   color: $primary;
//   padding-left: 1rem;
//   padding-right: 1rem;
// }

// .electronic_text {
//   font-family: "Gotham";
//   font-style: normal;
//   font-weight: 400;
//   font-size: 11px;
//   line-height: 23px;
//   color: rgba(0, 0, 0, 0.8);
//   padding-left: 1rem;
//   padding-right: 1rem;
// }

// .fund_title {
//   font-family: Gotham;
//   font-style: normal;
//   font-weight: 400;
//   font-size: 20px;
//   line-height: 30px;
//   color: $black;
//   padding-left: 1rem;
//   padding-right: 1rem;
// }
// .fund_amount {
//   font-family: Gotham;
//   font-style: normal;
//   font-weight: 400;
//   font-size: 30px;
//   line-height: 33px;
//   color: $black;
//   padding-left: 1rem;
//   padding-right: 1rem;
// }
// .funds_wrap {
//   // border: 1px solid #E5E5E5;
//   box-sizing: border-box;
//   padding: 40px 52px;
//   border-radius: 10px;
//   background: linear-gradient(0deg, #eff2fe, #eff2fe),
//     linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
//   box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
// }

// .funds_options {
//   margin: 10px 22px;
// }

// .fund_type {
//   padding: 5px 15px;
//   button {
//     height: 40px;
//     color: $secondary;
//     font-family: Gotham;
//     font-style: normal;
//     font-weight: normal;
//     font-size: 15px;
//     line-height: 30px;
//     box-sizing: border-box;
//     display: flex;
//     padding: 7px 25px;
//     align-items: center;
//     img {
//       padding-right: 10px;
//     }
//   }
//   .bank {
//     background: $primary;
//     outline: 0;
//     box-shadow: none;
//   }
//   .wallet_withdraw {
//     background: #1d95c8;
//     outline: 0;
//     box-shadow: none;
//     img {
//       width: 14%;
//     }
//   }
//   .paystack {
//     background: #56c1f2;
//     outline: 0;
//     box-shadow: none;
//   }
//   .flutter {
//     background: $yellow;
//     outline: 0;
//     box-shadow: none;
//   }
//   .enaira {
//     background: #16780d;
//     margin-bottom: 5px;
//     outline: 0;
//     box-shadow: none;
//   }

//   .paystackBtn {
//     color: $secondary;
//     border: none;
//     background: #56c1f2;
//     // background: $secondary;
//     font-family: Gotham;
//     font-style: normal;
//     font-weight: 400;
//     font-size: 14px;
//     // margin-left: 10px;
//     box-shadow: none;
//     outline: 0;
//     // max-width: 262px;
//     width: 100%;
//     border-radius: 5px;
//     font-size: 15px !important;
//     // height: 51px;
//     small {
//       font-size: 15px;
//     }
//   }
//   .paystackBtn:hover {
//     color: #1d3c4b;
//     font-size: 17px;
//     cursor: pointer;
//   }
// }
// // Payment option and fund me modal ends

// Bank transfer upload modal
// .payment_header_holder {
//   background: $primary;
// }

// .mb {
//   padding: 0;
// }

// .payment-header {
//   font-family: Gotham;
//   font-style: normal;
//   font-weight: 700;
//   font-size: 20px;
//   line-height: 30px;
//   display: flex;
//   align-items: flex-end;
//   color: $secondary;
// }

// .close_times {
//   color: #ea0000;
//   background: $secondary;
//   border-radius: 50%;
//   width: 10px;
//   height: 10px;
//   padding: 0px 6px;
// }

// .dot-holder {
//   text-align: left;
//   margin-top: -10px;
//   margin-bottom: 10px;
// }

// .double_arrow_holder {
//   p {
//     margin-top: 5px;
//     color: $primary;
//     font-family: Gotham;
//     font-style: normal;
//     font-weight: 700;
//     font-size: 15px;
//     line-height: 30px;
//   }
// }

// .step {
//   height: 15px;
//   width: 15px;
//   margin: 0 2px;
//   background-color: #bbbbbb;
//   border: none;
//   border-radius: 50%;
//   display: inline-block;
//   opacity: 0.5;
// }

// .step.active {
//   // opacity: 1;
//   background-color: $primary;
// }
// .step.finish {
//   background-color: #04aa6d;
// }

// #twoo {
//   display: none;
// }
// #threee {
//   display: none;
// }

// //  First step starts here
// .number__holder {
//   text-align: left;
//   display: flex;
//   p:first-child {
//     background: $primary;
//     // padding: 0.3rem 0.69rem;
//     border-radius: 50px;
//     color: $secondary;
//     text-align: center;
//     align-items: baseline;
//     margin-right: 16px;
//     font-family: Gotham;
//     font-style: normal;
//     font-weight: normal;
//     font-size: 16px;
//     width: 1.5rem;
//     height: 1.5rem;
//     padding: 0.5rem;
//     line-height: 11px;
//     // width: 2rem;
//     // height: 1.5rem;
//   }
//   p:nth-child(2) {
//     font-family: Gotham;
//     font-style: normal;
//     font-weight: bold;
//     font-size: 13px;
//     line-height: 22px;
//     color: $black;
//     margin-right: 1rem;
//   }
// }

// .sheltaAccount__details {
//   background: rgba(64, 123, 255, 0.46);
//   margin: 0 -16px 15px -16px;
//   // margin-bottom: 15px;
//   div {
//     display: flex;
//     justify-content: space-between;
//     margin: 0 3.5rem;
//     padding-top: 10px;
//     p:first-child {
//       font-family: Gotham;
//       font-style: normal;
//       font-weight: bold;
//       font-size: 16px;
//       line-height: 30px;
//       color: $black;
//       text-align: left;
//       margin-bottom: 0;
//     }

//     p:nth-child(2) {
//       font-family: Gotham;
//       font-style: normal;
//       font-weight: bold;
//       font-size: 16px;
//       line-height: 22px;
//       color: $black;
//       text-align: right;
//       margin-bottom: 0;
//     }
//   }
// }

// .next-btn {
//   background: $primary;
//   border-radius: 10px;
//   font-family: Gotham;
//   font-style: normal;
//   font-weight: 500;
//   font-size: 18px;
//   line-height: 30px;
//   color: $secondary;
//   margin-bottom: 1rem;
//   margin-top: 2rem;
//   box-shadow: none;
// }

// .desclaimer__text {
//   // padding-top: 4rem;
//   p {
//     font-family: Gotham;
//     font-style: normal;
//     font-weight: 500;
//     font-size: 15px;
//     line-height: 30px;
//     color: $primary;
//     text-align: left;
//     margin-left: 2.5rem;
//   }
// }

// .modal-foot {
//   border-top: none;
// }
// // Firts modal steps ends

// // Secosn modal steo start
// .info__text {
//   font-family: Gotham;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 18px;
//   line-height: 30px;
//   color: $black;
//   text-align: left;
// }

// .dateOf__payment {
//   label {
//     font-style: normal;
//     font-weight: 500;
//     font-size: 16px;
//     line-height: 30px;
//     display: flex;
//     color: $black;
//   }
//   input {
//     width: 100%;
//     border-bottom: 0.5px solid #6c6c6c !important;
//     border: none;
//     border-radius: 0;
//     box-shadow: none;
//   }
// }

// .upload__file {
//   .dropzone {
//     border: 0.5px solid #c4c4c4;
//     box-sizing: border-box;
//     border-radius: 10px;
//     margin: 20px 0;
//     padding: 50px 5px;
//     min-height: 200px;
//     cursor: pointer;
//     position: relative;
//     p {
//       margin-bottom: 0;
//       padding-top: 10px;
//       font-family: Gotham;
//       font-style: normal;
//       font-weight: normal;
//       font-size: 16px;
//       line-height: 30px;
//       color: $black;
//     }
//     .browse__span {
//       color: $primary;
//     }
//   }
// }
// .dropzone:hover {
//   background-color: lightgrey;
// }

// .input-file {
//   opacity: 0;
//   width: 100%;
//   left: 0%;
//   top: 0%;
//   height: 215px;
//   position: absolute;
//   cursor: pointer;
//   z-index: 1000;
// }
// .actual_image {
//   top: 0;
//   width: 100%;
//   height: 100%;
//   object-fit: contain;
//   z-index: 0;
//   position: absolute;
//   left: 0%;
//   cursor: pointer;
// }

// .third-step {
//   p {
//     font-family: Gotham;
//     font-style: normal;
//     font-weight: normal;
//     font-size: 15px;
//     line-height: 30px;
//     // display: flex;
//     // align-items: flex-end;
//     text-align: center;
//     color: $black;
//     padding-top: 3rem;
//     padding-bottom: 1rem;
//   }
// }

// .call-btn {
//   background: #f2f5fe;
//   border: 0.5px solid #6c6c6c;
//   box-sizing: border-box;
//   border-radius: 10px;
//   margin-bottom: 10px;
//   // width: 154px;
//   font-family: Gotham;
//   font-style: normal;
//   font-weight: 500;
//   font-size: 20px;
//   line-height: 30px;
//   box-shadow: none;
//   // display: flex;
//   // align-items: flex-end;
//   text-align: center;
//   color: $black;
//   padding: 6px 26px;
//   a {
//     text-decoration: none !important;
//     font-family: Gotham;
//     font-style: normal;
//     font-weight: normal;
//     img {
//       width: 27.01px;
//       margin-right: 1rem;
//     }
//   }
// }

// .back__arrow {
//   padding-top: 0.7rem;
//   cursor: pointer;
//   margin-right: 10px;
// }

// Second modal step ends

// Bank transfer modal upload ends

@media screen and (max-width: 599px) {
  .card_holder {
    padding-top: 20px;
  }
  .otp__btn {
    p {
      font-size: 12px;
    }
  }
  .agreement_text_header {
    h5 {
      font-size: 16px;
      line-height: 25px;
      padding: 28px 18px 0;
    }
    .blue_line {
      margin-left: 18px;
      width: 115px;
      height: 3px;
    }
  }
  .tenancy_header {
    h6 {
      margin: 20px 20px 2px;
      font-size: 15px;
    }
  }
  .agreement_body {
    h6 {
      padding: 25px 20px;
    }
  }
  .form_check {
    margin: 19px 20px 19px 20px;
  }
  .sign_agg_btn {
    padding: 19px 20px 42px;
  }

  // Fund me part begins
  .info__text {
    text-align: justify;
  }
  .funds_wrap {
    margin: 15px 0;
    padding: 40px 0;
  }
  .funds_options {
    margin: 10px 0px;
  }
  .document_text {
    font-size: 22px;
  }
  .check_img {
    img {
      width: 54px;
    }
  }
  // Fund me part ends

  // Bank transfer
  .otp-holder {
    input {
      width: 40px;
    }
  }
  .number__holder {
    p:nth-child(2) {
      line-height: 23px;
      text-align: left;
      font-size: 13px;
    }
  }
  .sheltaAccount__details {
    div {
      margin: 5px 15px;
      p:first-child {
        font-size: 13px;
      }
      p:nth-child(2) {
        font-size: 13px;
      }
    }
  }
  .second__number {
    padding-top: 2rem;
  }
  .desclaimer__text {
    // padding-top: 1rem;
    p {
      font-size: 13px;
      line-height: 25px;
      text-align: left;
    }
  }
  .payment-header {
    font-size: 15px;
  }
  .third-step {
    p {
      font-size: 13px;
      line-height: 24px;
      text-align: left;
      padding-top: 2rem;
    }
  }
  .call-btn {
    font-size: 13px;
    padding: 6px 34px;
    a {
      img {
        margin-right: 2rem;
        width: 20.01px;
      }
    }
  }
  .third-step {
    img {
      width: 7rem;
    }
  }
  // Bank transfer
}
</style>
